import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const RekrutskiTaSoldatskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "rekrutski-ta-soldatski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Рекрутські та солдатські пісні"
        description="Рекрутські пісні (також солдатські пісні) — пісні про важку службу юнаків у російській царській армії й у війську Австро-Угорської імперії."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Рекрутські та солдатські пісні
          </h1>
          <p className="typo-body mt-4 max-w-2xl">
            Рекрутські пісні (також солдатські пісні) — пісні про важку службу юнаків у російській царській армії й у
            війську Австро-Угорської імперії.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список рекрутських та солдатських пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру рекрутської та солдатської пісні
        </h2>
        <p className="typo-body mt-4">
          Рекрутські та солдатські пісні, як і чумацькі, мають багато спільного з козацькими піснями: в них звучить
          туга за домівкою, за ріднею, нарікання на долю; один з провідних мотивів — прокляття панам, які насильно
          оддали в солдати, офіцерам, які знущалися з новобранців; зображені картини кривавих боїв, страждань і смерть
          поранених, які ненавидять тих, хто затівав війну.
        </p>
        <p className="typo-body italic mt-4">
          А хто хоче воювати,
          <br />
          Дай му, Боже, помирати!
        </p>
        <p className="typo-body mt-4">
          Велику групу складають пісні про рекрутський набір: це були, як пише Г. Хоткевич, пісні “безпомічності,
          покірності всесильному режимові:
        </p>
        <p className="typo-body italic mt-4">
          Ох, і то ж не маки —
          <br />
          То наші козаки,
          <br />
          Тож наші козаки,
          <br />
          Та все новобранці,
          <br />
          Що понабирали
          <br />
          В неділеньку вранці…
        </p>
        <p className="typo-body mt-4">
          І дивний незвичайний хаос утворили ці звуки, перемішавшися зі стоном матерів, сумом батьків і риданнями
          покинутих дівчат”.
        </p>
        <p className="typo-body mt-4">
          У піснях, створених на землях Західної України, що тривалий час була під владою цісарської Австро-Угорщини,
          також відбита трагедія рекрутів: незрозумілі порядки, чужа мова, чужа сторона, покарання, безкінечна муштра,
          а попереду кров, яку проливатимуть жовніри заради чужої вигоди. Для них служба — то страшна мука — бо “наші
          руки нігди того не робили, но сіяли та й орали, за плугом ходили”.
        </p>
        <p className="typo-body mt-4">
          Найтяжча доля випадала біднякам, “бо багацькі гроші мають, то ся викупляють”, а сироти мусять відбувати повний
          термін, тому солдатські й рекрутські пісні сповнені скорботою, жалем, і часом підносяться до традиційних вершин.
        </p>
      </div>
    </>
  );
};

export default RekrutskiTaSoldatskiPisniPage;
